import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDefaultImageService {
  letters = 'abcdefghijklmnopqrstuvwxyz';
  default: string = '';
  defaultChar: string = '';

  constructor() { }

  setDefaultAvatar(
    firstName: string = '',
    lastName: string = '',
    email: string = '',
    username: string = '',
    shape: string = 'circle'
  ): string {
	try {
    if (this.checkAlphabet(firstName)) {
      this.defaultChar = firstName.toLowerCase().charAt(0);
    } else if (this.checkAlphabet(lastName)) {
      this.defaultChar = lastName.toLowerCase().charAt(0);
    } else if (this.checkAlphabet(email)) {
      this.defaultChar = email.toLowerCase().charAt(0);
    } else if (this.checkAlphabet(username)) {
      this.defaultChar = username.toLowerCase().charAt(0);
    }} catch(e: any) {
	console.log(e, "in user avatar");
	this.defaultChar = '';
	}
    return this.setDefault('user', shape);
  }

  setDefaultOrganizationAvatar(title: string, slug: string): string {
    this.defaultChar = '';
    title = title.split(' ').join('');
    if (this.checkAlphabet(title)) {
      this.defaultChar = title.toLowerCase().charAt(0);
    } else if (this.checkAlphabet(slug)) {
      this.defaultChar = slug.toLowerCase().charAt(0);
    }
    return this.setDefault('organization');
  }

  checkAlphabet(name: string): boolean {
    const lowerCased = name.toLowerCase();
    for (let char of lowerCased) {
      if (!this.letters.includes(char)) return false;
    }
    return true;
  }

  setDefault(type: string, shape: string = 'circle'): string {
    if (this.defaultChar === '') {
      this.default = 'https://s3.amazonaws.com/sparro-global/profile_pic.svg';
    } else if (type === 'organization') {
      this.default = `https://uni-common-assets.s3.amazonaws.com/icons/organization/${this.defaultChar}-blue-120px.svg`;
    } else {
      this.default = `https://uni-common-assets.s3.amazonaws.com/icons/avatar/svg/${shape}/${this.defaultChar}-red-120px.svg`;
    }
    return this.default;
  }
}
