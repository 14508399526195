import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { Subject } from 'rxjs';
import { User } from '@sparro/app/core/models/user.model';
import { map } from 'rxjs/operators';
import { MetaService } from '../meta/meta.service';

@Injectable({
  providedIn: 'root'
})
export class StorageHandlerService {
  private baseUrl: string = `${environment.baseUrl}`;
  authChange: Subject<User> = new Subject();
  blogOwnerDetail = new Subject();
  publicationOwnerDetail = new Subject();
  projectOwnerDetail = new Subject();
  organizationMemberDetail = new Subject();
  user: User;
  blogOwner: object; // We can have better types than object
  projectOwner: object;
  publicationOwner: object;
  organizationMember: object;
  authenticated: boolean = false;
  timeout: number = 86400000; // 1 day in ms

  constructor(private http: HttpClient, private metaService: MetaService) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  storeItem(key: string, value: any): void {
    const timeStampedValue = { value, timestamp: new Date().getTime().toString() };
    let stringifiedValue = JSON.stringify(timeStampedValue);
    stringifiedValue = stringifiedValue.replace(/\s/g, '');
    stringifiedValue = stringifiedValue.replace(
      /[\u00A0-\u2666]/g,
      c => '&#' + c.charCodeAt(0) + ';'
    );
    const encodedValue = btoa(unescape(stringifiedValue));
    localStorage.setItem(key, encodedValue);
  }

  getItem(key: string): any {
    const data = localStorage.getItem(key);
    if (data !== null) {
      const decodedString = decodeURIComponent(escape(atob(data)));
      const timeStampedJSON = JSON.parse(decodedString);
      const jsonData = timeStampedJSON.value;
      const now = new Date().getTime();
      const timestamp = parseInt(timeStampedJSON.timestamp);
      if ((timestamp && now - timestamp > this.timeout) || !timestamp) {
        this.removeItem(key);
        return null;
      }
      return jsonData;
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getUser() {
    return this.http.get(this.baseUrl + 'profiles/auth/').pipe(
      map(response => {
        return this.metaService.decodeApiResponse(response);
      })
    );
  }

  getHeavyUser(user: number) {
    return this.http.get(this.baseUrl + 'profiles/' + user + '/heavy/');
  }

  getCurrentUserSubject() {
    return this.user;
  }

  getCurrentUserLocalStorage() {
    const data = localStorage.getItem('user');
    if (data) {
      const decodedString = decodeURIComponent(escape(atob(data)));
      return JSON.parse(decodedString);
    } else {
      return data;
    }
  }

  setCurrentUserLocalStorage(user) {
    let stringifyData = JSON.stringify(user);
    stringifyData = stringifyData.replace(/\s/g, '');
    const encodedUrl = btoa(unescape(stringifyData));
    if (this.getCurrentUserLocalStorage() != null) {
      localStorage.removeItem('user');
    }
    localStorage.setItem('user', encodedUrl);
  }

  getEntityOwnerSubject(entityType) {
    if (entityType === 'journals') {
      return this.blogOwner;
    }
    if (entityType === 'publications') {
      return this.publicationOwner;
    }
    if (entityType === 'projects') {
      return this.projectOwner;
    }
    if (entityType === 'organizations') {
      return this.organizationMember;
    }
  }

  setEntityOwnerSubject(entityType: string, owner) {
    if (entityType === 'journals') {
      this.blogOwner = owner;
      this.blogOwnerDetail.next(owner);
    }
    if (entityType === 'publications') {
      this.publicationOwner = owner;
      this.publicationOwnerDetail.next(owner);
    }
    if (entityType === 'projects') {
      this.projectOwner = owner;
      this.projectOwnerDetail.next(owner);
    }
    if (entityType === 'organizations') {
      this.organizationMember = owner;
      this.organizationMemberDetail.next(owner);
    }
  }

  getEntityOwnerLocalStorage(entityType: string) {
    const data = localStorage.getItem(entityType);
    if (data == null) {
      return data;
    } else {
      const decodedString = decodeURIComponent(escape(atob(data)));
      const jsonData = JSON.parse(decodedString);
      return jsonData;
    }
  }

  setEntityOwnerLocalStorage(ownerDetail, entityType) {
    let stringifyData = JSON.stringify(ownerDetail);
    stringifyData = stringifyData.replace(/\s/g, '');
    const encodedUrl = btoa(unescape(stringifyData));
    localStorage.setItem(entityType, encodedUrl);
  }

  // apis in helper???
  // TODO: Remove this from here
  getEditViewBlogInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'blog/self/' + uuid + '/');
  }

  getEditViewPublicationInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'publication/self/' + uuid + '/');
  }

  getEditViewProjectInfo(uuid: string) {
    return this.http.get(this.baseUrl + 'neo_project/self/' + uuid + '/teams/');
  }

  getDetailViewBlogInfo(slug: string) {
    return this.http.get(this.baseUrl + 'blogs/' + slug + '/');
  }

  getDetailViewPublicationInfo(slug: string) {
    return this.http.get(this.baseUrl + 'publications/' + slug + '/');
  }

  getDetailViewProjectInfo(slug: string) {
    return this.http.get(this.baseUrl + 'neo_projects/' + slug + '/meta/');
  }

  getOrganizationMemberInfo(slug: string) {
    return this.http.get(this.baseUrl + 'organizations/' + slug + '/is_member/');
  }

  setProjectPermission(key: string, data: any): void {
    let value = this.getItem(key);
    const localData = value ? value : {};
    localData[data.uuid] = data.type;
    this.storeItem(key, localData);
  }

  getProjectPermission(key: string, searchField: string): string {
    const uuids = this.getItem(key);
    return uuids && searchField in uuids ? uuids[searchField] : null;
  }
}
